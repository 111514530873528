<template>
  <Row :gutter="32">
    <Col span="4" style="text-align: left">
      <Card class="menuOperation" shadow>
        <p slot="title">菜单列表</p>
        <Button type="primary" size="small" long ghost @click="createTopMenu">添加一级菜单</Button>
        <Tree :data="menuTreeData" ref="menuTree" @on-contextmenu="handleContextMenu" @on-select-change="handleSelect">
          <template slot="contextMenu">
            <DropdownItem @click.native="handleEditMenu">编辑菜单</DropdownItem>
            <DropdownItem @click.native="handleCreateChildMenu">添加子菜单</DropdownItem>
            <DropdownItem @click.native="handleDeleteMenu" style="color: #ed4014">删除</DropdownItem>
          </template>
        </Tree>
      </Card>
    </Col>
    <Col span="20">
      <Card class="menuOperation" shadow>
        <p slot="title" style="text-align: left">{{ title }}</p>
        <Form ref='topMenuForm' :model="formData">
          <Row :gutter="32">
            <Col span="12">
              <FormItem label="显示名称" prop="title" label-position="top">
                <Input v-model="formData.title" placeholder="显示名称" />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="路由地址" prop="path" label-position="top">
                <Input v-model="formData.path" placeholder="路由地址" />
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="32">
            <Col span="12">
              <FormItem label="组件名称" prop="name" label-position="top">
                <Input v-model="formData.name" placeholder="组件名称" />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="组件路径" prop="component" label-position="top">
                <Input v-model="formData.component" placeholder="组件路径" />
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="32">
            <Col span="12">
              <FormItem label="跳转路径" prop="redirect" label-position="top">
                <Input v-model="formData.redirect" placeholder="跳转路径" />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="菜单图标" prop="icon" label-position="top">
                <Input v-model.number="formData.icon" placeholder="菜单图标" />
<!--                <Select v-model="formData.icon" multiple placeholder="菜单图标">-->
<!--                   <Option v-for="(roleObj, index) in drawer.roles" :value="roleObj.ID" :key="index">{{ roleObj.role }}</Option>-->
<!--                </Select>-->
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="菜单顺序" prop="index" label-position="top">
                <Input v-model.number="formData.index" placeholder="菜单顺序" />
              </FormItem>
            </Col>
          </Row>
        </Form>
        <div class="demo-drawer-footer">
          <Button type="primary" style="margin-right: 8px" @click="submitTopMenu">提 交</Button>
          <Button  @click="reset">重 置</Button>
        </div>
      </Card>
      <base-drawer :is-show="drawer.isShow" :title="drawer.title" @closeDrawer="closeDrawer" @submit="submit">
        <Form ref='childMenuForm' :model="childMenu">
          <Row :gutter="32">
            <Col span="12">
              <FormItem label="显示名称" prop="title" label-position="top">
                <Input v-model="childMenu.title" placeholder="显示名称" />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="路由地址" prop="path" label-position="top">
                <Input v-model="childMenu.path" placeholder="路由地址" />
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="32">
            <Col span="12">
              <FormItem label="组件名称" prop="name" label-position="top">
                <Input v-model="childMenu.name" placeholder="组件名称" />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="组件路径" prop="component" label-position="top">
                <Input v-model="childMenu.component" placeholder="组件路径" />
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="32">
            <Col span="12">
              <FormItem label="跳转路径" prop="redirect" label-position="top">
                <Input v-model="childMenu.redirect" placeholder="跳转路径" />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="菜单图标" prop="icon" label-position="top">
                <Input v-model.trim="childMenu.icon" placeholder="菜单图标" />
<!--                <Select v-model="childMenu.icon" multiple placeholder="菜单图标">-->
<!--                    <Option v-for="(roleObj, index) in drawer.roles" :value="roleObj.ID" :key="index">{{ roleObj.role }}</Option>-->
<!--                </Select>-->
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="32">
            <Col span="12">
              <FormItem label="菜单顺序" prop="index" label-position="top">
                <Input v-model.number="childMenu.index" placeholder="菜单顺序" />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </base-drawer>
    </Col>
  </Row>
</template>
<script>
import {get, put, del, post} from "@/api/http.js";
import Notice from "@/components/baseNotice";
import BaseDrawer from "@/components/base/baseDrawer";
import {menuSort} from "@/utils/utils.js";

// function sortBy(menuTree) {
//   for (let menuItem of menuTree) {
//     if (menuItem.children) {
//       console.log(menuItem.children)
//       menuItem.children = _.orderBy(menuItem.children, 'index')
//       sortBy(menuItem.children)
//     }
//   }
//   return menuTree
// }

export default {
  name: "MenuManage",
  components: {
    BaseDrawer
  },
  data () {
    return {
      contextData: null,
      title: "编辑菜单",
      edit: false,
      menuTreeData: [],
      drawer: {
        isShow: false,
        title: ""
      },
      childMenu: {
        pid: null,
        path: "",
        name: "",
        title: "",
        index: 0,
        icon: "",
        redirect: "",
        component: ""
      },
      formData: {
        "pid": 0,
        "path": '',
        "name": '',
        "title": '',
        "ico": '',
        "index": 0,
        "redirect": '',
        "component": ''
      }
    }
  },
  methods: {
    createTopMenu() {
      this.title = "添加一级菜单"
      this.resetTopMenuForm()
    },
    getMenus(){
      get("/api/v1/menu", "").then((resp)=>{
        this.menuTreeData = menuSort(this.$_.orderBy(resp.data, 'index'))
      })
    },
    handleContextMenu (data) {
      this.contextData = data;
    },
    handleSelect(data) {
      this.edit = true
      this.contextData = this.$_.cloneDeep(data[0]);
      this.formData = this.$_.cloneDeep(data[0]);
    },
    handleEditMenu () {
      this.title = "编辑菜单"
      this.edit = true
      this.formData = this.$_.cloneDeep(this.contextData);
    },
    handleCreateChildMenu() {
      this.childMenu.pid = this.contextData.id;
      this.drawer.title = "添加子菜单: " + this.contextData.title;
      this.$refs.childMenuForm.resetFields()
      this.drawer.isShow = true;
    },
    handleDeleteMenu () {
      del("/api/v1/menu", {"id": [this.contextData.id]})
          .then(()=>{
            this.getMenus();
            Notice.success("删除操作", "删除成功")
          })
          .catch((e)=>{
            return e
          })
    },
    reset() {
      this.formData = this.$_.cloneDeep(this.contextData);
    },
    submitTopMenu(){
      if (this.edit) {
        this.edit = false
        put("/api/v1/menu", this.formData)
            .then(()=>{
              this.getMenus();
              Notice.success("提交操作", "更新成功")
            })
            .catch((e)=>{
              Notice.error("提交操作", "更新失败:"+ e)
            })
      } else {
        post("/api/v1/menu", this.formData)
            .then(()=>{
              this.getMenus();
              Notice.success("提交操作", "添加成功")
            })
            .catch((e)=>{
              Notice.error("提交操作", "添加失败:"+ e)
            })
      }
    },
    submit(){
      post("/api/v1/menu", this.childMenu)
          .then(()=>{
            this.getMenus();
            Notice.success("提交操作", "添加成功")
            this.drawer.isShow = false;
          })
          .catch((e)=>{
            return e
          })
    },
    resetTopMenuForm() {
      this.formData = {
          "pid": 0,
          "path": '',
          "name": '',
          "title": '',
          "ico": '',
          "index": 0,
          "redirect": '',
          "component": ''
      }
    },
    closeDrawer() {
      this.drawer.isShow = false;
    },
  },
  created() {
    this.getMenus()
  }
}
</script>
<style scoped>

.menuOperation {
  min-height: calc(100vh - 100px);
}

.demo-drawer-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: center;
  background: #fff;
}
</style>