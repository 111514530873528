import _ from "lodash";

export function menuSort(menuTree) {
    for (let menuItem of menuTree) {
        if (menuItem.children) {
            menuItem.children = _.orderBy(menuItem.children, 'index')
            menuSort(menuItem.children)
        }
    }
    return menuTree
}

export default {}